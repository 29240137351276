<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body h-100">
        <div class="mb-1">
          <div class="row justify-content-between">
            <div class="col-md-4 col-sm-6 mb-1">
              <h3>
                ตัวแทน {{ countpeople }} คน
              </h3>
            </div>
            <div class="col-xl-3 col-md-4 col-sm-6 mb-1">
              <b-button
                variant="success"
                class="col-12"
                block
                @click="onAddButton"
              >
                เพิ่มตัวแทน
              </b-button>
            </div>

          </div>

          <div class="separator" />
        </div>
        <div class="row justify-content-between">
          <div class="col-md-4 col-xl-3 col-sm-6 mb-1">
            <b-form-group style="margin-bottom: 0rem;">
              <div class="d-flex align-items-center">
                <label class="mr-2">ค้นหา</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="ค้นหา"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
          <div class="col-md-4 col-xl-3 col-sm-6 mb-1">
            <b-form-group style="margin-bottom: 0rem;">
              <div class="d-flex align-items-center">
                <label class="mr-1">ประเทศ</label>
                <b-form-select
                  v-model="country"
                  :options="DataCountry"
                />
              </div>
            </b-form-group>
          </div>
        </div>
        <b-table
          striped
          hover
          responsive
          class="type-relative"
          :per-page="inputsnode.perPage"
          :current-page="inputsnode.currentPage"
          :items="list"
          :fields="fields"
          :filter="searchTerm"
          show-empty
          @filtered="onFiltered"
        >
          <template #cell(name)="data">
            {{ data.item.firstName }} {{ data.item.lastName }}
          </template>
          <template #cell(country)="data">
            {{ data.item.country.thName }}
          </template>
          <template #cell(button)="data">
            <div class="d-grid d-md-block">
              <button
                class="btn btn-warning mr-1"
                type="button"
                @click="onEditButton(data.item.code)"
              >
                แก้ไข
              </button>
              <button
                class="btn btn-danger mr-1"
                type="button"
                @click="removeCustomer(data.item._id)"
              >
                ลบ
              </button>
              <button
                class="btn btn-info mr-1"
                type="button"
                @click="onClickOrder(data.item._id)"
              >
                การขาย
              </button>
            </div>
          </template>
          <template #empty>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลตัวแทน</h4>
            </div>
          </template>
          <template #emptyfiltered>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลตัวแทน</h4>
            </div>
          </template>
        </b-table>
        <b-card-body
          v-if="totalItem > 0"
          class="d-flex justify-content-between flex-wrap pt-0 mt-2"
        >

          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="inputsnode.perPage"
              size="sm"
              inline
              :options="inputsnode.pageOptions"
            />
          </b-form-group>

          <div>
            <b-pagination
              v-model="inputsnode.currentPage"
              :total-rows="inputsnode.totalRows"
              :per-page="inputsnode.perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </div>

    </div>

  </div>

</template>
<script>
import { defineComponent } from '@vue/composition-api'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BButtonGroup
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import router from '@/router'

export default defineComponent({
  name: 'customer_list',
  components: {
    BTable,
    BAvatar,
    BBadge,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormSelect,
    BPagination,
    BCardBody,
    BFormGroup,
    BCardCode,
    ToastificationContent,
    BButtonGroup
  },
  watch: {
    type (val) {
      if (val === 1) {
        this.searchTerm = ''
      } else if (val === 2) {
        this.searchTerm = 'V'
      } else if (val === 3) {
        this.searchTerm = 'U'
      }

      for (let i = 0; i < 3; i += 1) {
        if (i + 1 === val) {
          this.outline[i] = 'primary'
        } else {
          this.outline[i] = 'outline-primary'
        }
      }
    },
    country (val) {
      this.loadCustomer()
    }
  },
  data () {
    return {
      country: '',
      DataCountry: [],
      searchTerm: '',
      outline: ['primary', 'outline-primary', 'outline-primary'],
      fields: [
        {
          key: 'code', label: 'รหัสตัวแทน', tdClass: 'text-center', thClass: 'text-center', thStyle: 'min-width: 150px', sortable: true
        },
        {
          key: 'name', label: 'ชื่อตัวแทน', thStyle: 'min-width: 250px'
        },
        {
          key: 'country', label: 'ประเทศ', thStyle: 'min-width: 100px'
        },
        { key: 'telephoneNumber', label: 'เบอร์โทร', thStyle: 'min-width: 150px' },
        { key: 'button', label: '', thClass: 'text-center', thStyle: 'min-width: 330px' }

      ],
      inputsnode: {
        perPage: 10,
        pageOptions: [10, 25, 50],
        totalRows: 0,
        itemsPerPage: 10,
        currentPage: 1,
        startItem: 0,
        endItem: 10
      },
      type: '',
      list: [],
      countpeople: 0

    }
  },
  computed: {
    listCustomer () {
      return this.$store.state.customer.listCustomer
    },
    totalItem () {
      return this.inputsnode.totalRows
    }
  },
  methods: {
    loadCountry () {
      this.DataCountry = [
        { value: '', text: 'ทั้งหมด' }
      ]
      this.$store.dispatch('customer/getCountry').then(result => {
        result.data.items.forEach(element => {
          this.DataCountry.push({ value: element._id, text: element.thName })
        })
      })
    },
    setType (val) {
      this.type = val
    },
    onFiltered (filteredItems) {
      this.inputsnode.totalRows = filteredItems.length
      this.inputsnode.currentPage = 1
    },
    loadCustomer () {
      this.$store.dispatch('customer/getAgent', { countryId: this.country })
        .then(response => {
          console.log(response)
          console.log(response.data.totalItem)
          this.countpeople = response.data.totalItem
          this.inputsnode.totalRows = response.data.totalItem
          this.list = response.data.items
        }).catch(error => {
          console.log(error)
        })
    },
    onClickOrder (id) {
      this.$router.push({ path: `/customer/order/${id}` })
    },
    onAddButton () {
      this.$router.push({ path: '/customer/add/V' })
    },
    onEditButton (code) {
      this.$router.push({ path: `/customer/${code}/edit` })
    },
    removeCustomer (id) {
      if (id !== '' && id !== undefined) {
        this.$swal({
          title: 'คุณต้องการจะลบข้อมูลนี้?',
          text: 'คุณมั่นใจรึเปล่า ? คุณจะไม่สามารถนำข้อมูลนี้กลับมาได้!',
          type: 'warning',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn-success mr-1',
          confirmButtonText: 'ยืนยัน',
          cancelButtonClass: 'btn-danger',
          cancelButtonText: 'ยกเลิก'
        }).then(result => {
          if (result.value) {
            this.$store.dispatch('customer/removeCustomer', { id })
              .then(response => {
                if (response.data.success) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'ตัวแทน',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: response.data.message
                    }
                  })
                  this.loadCustomer()
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'ตัวแทน',
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: response.data.message
                    }
                  })
                }
              }).catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ตัวแทน',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: error.message
                  }
                })
              })
          }
        })
      }
    }
  },
  created () {
    this.loadCustomer()
    this.loadCountry()
  }
})
</script>
<style scoped>
.btBetween{
  width: 88px;height: 2.714rem !important;
  height: 25px;margin-right: 3px;
}
</style>
